@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";
@import "~bootstrap/dist/css/bootstrap.min.css";

@import url("https://fonts.googleapis.com/css2?family=Outfit:wght@300;500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Outfit:wght@300;400;500&display=swap");
@import url("https://cdnjs.cloudflare.com/ajax/libs/animate.css/3.5.2/animate.min.css");

.App {
	font-family: "Outfit", sans-serif;
	font-weight: 300;
	color: #080b0b;
}
ul {
	list-style-type: none;
}

.wrapper {
	max-width: 1050px;
	width: 100%;
	margin: 0 auto;
	margin-bottom: 200px;
	margin-top: 100px;
}
